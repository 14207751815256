import React from 'react';
import NavBar from '../atoms/NavBar';
import { Typography } from '@material-ui/core';

const Index = (props: any) => {
    return (
        <div>
            <NavBar />
            <h3> index </h3>
        </div>
    );
};

export default Index;
